<script setup>
  import { computed } from 'vue';
  const props = defineProps({
    type: {
      type: String,
      default: 'submit'
    },
    href: {
      type: String,
      default: null
    },
    class: {
      type: String,
      default: ''
    }
  });

  const computedClass = computed(() => {
    return [
      'main-button',
      'hover:!bg-primary-dark',
      'inline-flex',
      'items-center',
      'rounded-md',
      'border',
      'border-transparent',
      'bg-primary',
      'px-5',
      'py-2',
      'text-[1rem]',
      'font-semibold',
      'capitalize',
      '!text-white',
      'transition',
      'duration-300',
      'ease-in-out',
      'hover:border',
      props.class // Custom class passed via the prop
    ];
  });
</script>

<template>
  <div>
    <a v-if="href" :href="href" :class="computedClass">
      <slot />
    </a>
    <button v-else :type="type" :class="computedClass">
      <slot />
    </button>
  </div>
</template>

<style scoped>
  .main-button {
    overflow: hidden;
    transition:
      background-color 0.25s ease-in-out,
      box-shadow 0.25s ease-in-out;
  }
  .main-button:hover {
    color: white !important;
    box-shadow: inset 0 0 0 2px white;
  }
</style>
